import React, { useState, useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react'
import { Header, Icon } from 'semantic-ui-react'
import rating from '../img/rating.png'
import shop from '../img/shop.png'

const Iframe = (props) => {
  return (<div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />);
}
const About = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return function cleanup() {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  });
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  const iframe = `<iframe width=${width >= 768 ? 550 : 300} height=${width >= 768 ? 450 : 250} frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=place_id:EjExOTAgU2FuZHJpbmdoYW0gUmQsIEludGFrZSwgRG9uY2FzdGVyIEROMiA1SkUsIFVLIjESLwoUChIJ880mSGkOeUgRhw2wmJI7YVgQvgEqFAoSCa3OBSlvDnlIERceUZMbqhk6&key=AIzaSyDCBlO16OGvhBs27XxTf8tnPkT8fS304Nc" allowfullscreen></iframe>`
  return (
    <div className={css(styles.wrapper, props.hide && styles.hide)}>
      <div className={css(styles.mapSection)}>
        <Header>Where to find us</Header>
        <Iframe iframe={iframe} />
      </div>
      <div className={css(styles.section)}>
        <Header>Opening Hours</Header>
        <p><b>Open on all Bank Holidays</b></p>
        <p><b>Monday:</b> Closed</p>
        <p><b>Tuesday:</b> 17:00 - 23:00</p>
        <p><b>Wednesday:</b> 17:00 - 23:00</p>
        <p><b>Thursday :</b> 17:00 - 23:00</p>
        <p><b>Friday:</b> 17:00 - 23:30</p>
        <p><b>Saturday:</b> 17:00 - 23:30</p>
        <p><b>Sunday:</b> 17:00 - 23:00</p>
      </div>
      <div className={css(styles.section)}>
        <Header>Telephone Number</Header>
        <Header color="red">01302 323063</Header>
        <Header>Get in touch</Header>
        <p><Icon name='mail'/><b>Email:</b> chinared.takeaway@gmail.com</p>
        <p><a href="https://www.facebook.com/chinared190"><Icon size='big' name='facebook official' /></a></p>
        <Header>Payment Methods</Header>
        <Icon size='big' name='cc mastercard' />
        <Icon size='big' name='cc visa' />
      </div>
      <div className={css(styles.photoSection)}>
        <img src={rating} className={css(styles.photo)} />
        <img src={shop} className={css(styles.photo)} />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    '@media (min-width: 768px)': {
      width: "600px"
    },
    '@media (max-width: 767px)': {
      flexGrow: 1,
      paddingBottom: "50px",
    },
    padding: "20px",
    display: "flex",
    flexWrap: "wrap",
  },
  section: {
    textAlign: "center",
    margin: "auto",
    width: "250px",
    marginTop: "20px",
  },
  hide: {
    display: 'none'
  },
  photoSection: {
    display: 'flex',
    flexWrap: "wrap",
    margin: "auto"
  },
  photo: {
    height: "220px",
    width: "220px",
    margin: "auto",
    marginTop: "20px",
  },
  mapSection: {
    margin: "auto",
  }
});
export default observer(About);
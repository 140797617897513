import React, { useState, useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Header, Modal, Button, Icon } from 'semantic-ui-react'
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react'

const ShopClosedModal = (props) => {
  const {basket, forceClose, forceOpen} = props;
  const [time, setTime] = useState(new Date());
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmed, setModalConfirmed] = useState(false);

  useEffect(() => {
      let intervalID = setInterval(
        () => tick(), 1000);
      return function cleanup() {
        clearInterval(intervalID);
      };
  });

  const tick = ()=> {
    const local = new Date().toLocaleString("en-GB", { timeZone: 'Europe/London' });
    const localDate = local.split(",")[0].split("/");
    setTime(new Date(`${localDate[1]}/${localDate[0]}/${localDate[2]}, ${local.split(",")[1]}`));
    if (forceOpen) {
        setModalOpen(false);
        basket.setShopClosed(false);
        return;
    };
    if (forceClose) {
        !modalConfirmed ? setModalOpen(true) : setModalOpen(false);
        basket.setShopClosed(true);
        return;
    };
    const h = time.getHours();
    const m = time.getMinutes();
    const dayOfWeek = time.getDay();

    // close at 23:00
    let closeMinute = 0;
    if (dayOfWeek=== 5 ||dayOfWeek === 6){
      // close at 23:30 on Fri and Sat
      closeMinute= 30;
    }
    if(h < 17 || (h === 23 && m >= closeMinute) || dayOfWeek === 1) {
        basket.setShopClosed(true);
      !modalConfirmed ? setModalOpen(true) : setModalOpen(false);
    } else {
      setModalOpen(false);
      basket.setShopClosed(false);
    }
  };

  const closeModal = () => {
    setModalConfirmed(true);
    setModalOpen(false);
  }

  return <Modal
    open={modalOpen}
    onClose={()=>closeModal()}
    basic
    size='small'
    className={css(styles.modal)}
  >
    <Header color="red" as="h1" content='We are closed'/>
    <Modal.Content>
      <h3>Please check our opening hours and announcements</h3>
      <h3>If you would like to pre-order, message us on <a href="https://www.facebook.com/chinared190"><Icon size='large' name='facebook official' /></a> or send us an email at chinared.takeaway@gmail.com</h3>
    </Modal.Content>
    <Modal.Actions>
      <Button color='green' className={css(styles.modalAction)}onClick={()=> closeModal()} inverted>
        <Icon name='checkmark' /> Got it
      </Button>
    </Modal.Actions>
  </Modal>
}

const styles = StyleSheet.create({
  modal: {
    textAlign: "center"
  },
  modalAction: {
    display: 'flex',
    margin: "auto"
  }
});
export default observer(ShopClosedModal);
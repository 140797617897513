import React, {useEffect, useState} from 'react';
import { StyleSheet, css } from 'aphrodite';
import { observer } from 'mobx-react'
import NavBar from '../headerComponents/navBar'
import Footer from '../footer'
import { Button, Header, Divider } from 'semantic-ui-react'
import BasketItem from '../basket/basketItem'
import {toFixed} from '../../utils/ultils'

const CheckoutSuccess = () =>{
    const [order, setOrder] = useState(null);
    useEffect(() => {
        async function getOrder() {
            const urlParams = new URLSearchParams(window.location.search);
            const orderId = decodeURIComponent(urlParams.get('orderId'));
            const order = await fetch("/api/getOrderById", {
              method: "post",
              body: JSON.stringify({orderId}),
              headers: {
                'Content-Type': 'application/json'
              }
            });
            setOrder(await order.json());
        }
        getOrder();
      }, []);

    const receipt = () => {
        if (order) {
            const total = toFixed(order.amount/100);
            const delivery = order.isDelivery === "true" ? parseFloat(process.env.REACT_APP_DELIVERY_CHARGE) : 0;
            const service = order.usingCard ? toFixed(parseFloat(process.env.REACT_APP_SERVICE_CHARGE)) : 0;
            const subTotal = toFixed(total - delivery - service);


            return <div className={css(styles.summary)}>
                <Header as="h2" className={css(styles.title)}>Your order <div className={css(styles.orderId)}>Id: {order.id}</div></Header>
                {order.isDelivery === "true" ? <Header as="h5" className={css(styles.info)} >Delivering to {order.address + ", " + order.postcode}</Header> : 
                    <Header as="h5" className={css(styles.info)}>Picking up by {order.name} ({order.phone})</Header>}
                <Divider/>
                {order.order.map((item, index) => {
                return <BasketItem key={index} itemName={item.itemName} price={item.price} basket={null} qty={item.qty} subItems={item.subItems} viewOnly={true}/>
            })} 
                <Divider/>
                <div>
                    <div className={css(styles.line)}><div className={css(styles.lable)}><b>Subtotal</b>: </div><b>£ {subTotal}</b></div>
                    {order.isDelivery === "true" && <div className={css(styles.line)}><div className={css(styles.lable)}>Delivery: </div>£ {delivery}</div>}
                    {order.usingCard && <div className={css(styles.line)}><div className={css(styles.lable)}>Service: </div>£ {service}</div>}
                    <div className={css(styles.total)}><div className={css(styles.lable)}><Header as='h4' textAlign="left">Total: </Header> </div>
                        <Header as='h4' className={css(styles.header)}>£ {total}</Header></div>
                </div>
            </div>
        }
    }

    return (
        <div>
            <NavBar />
            <div className={css(styles.container)}>
                <Header as="h1" color="green">Order successfully placed</Header>
                <Header as="h5">Please note that delivery orders typically take 30 - 60 minutes, collections take 10 - 20 minutes</Header>
                <Header as="h5">Please allow more time at peak times and certain dates, e.g. Fridays/Saturdays, Christmas Eve, New Years Eve etc.</Header>
                {receipt()}
                <Button primary size='huge' icon className={css(styles.button)} onClick={() => { document.location.href = "/"; }}>
                    Back
                </Button>
            </div>
            <Footer />
        </div>
    );
}

const styles = StyleSheet.create({
    container: {
        margin: "auto",
        padding: '20px'
    },
    button: {
        width: "200px",
        marginTop: "50px"
    },
    line: {
        display: "flex",
        margin: "auto",
        textAlign: "left"
    },
    total: {
        display: "flex",
        margin: "auto",
        marginTop: "20px"
    },
    lable: {
        flexGrow: 1,
    },
    header: {
        marginTop: "0px",
    },
    summary: {
        border: "1px solid rgba(34,36,38,.15)",
        padding: "10px",
        maxWidth: "450px",
        margin:"auto"
    },
    orderId: {
        fontSize: "10px"
    },
    info: {
        marginTop:0
    },
    title: {
        marginBottom: 0
    }
  }
);

export default observer(CheckoutSuccess);
import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button, Form, Message, Header, TextArea, Icon, Modal, Divider } from 'semantic-ui-react'
import CardSection from './cardSection';
import { observer } from 'mobx-react'
import BasketStore from '../../states/basket'
import store from "store"
import BasketPopupView from './basketPopupView'
import { StyleSheet, css } from 'aphrodite';
import { useHistory } from "react-router-dom";
const mbxClient = require('@mapbox/mapbox-sdk');
const mbxGeocoding = require('@mapbox/mapbox-sdk/services/geocoding');
const mbxDirections = require('@mapbox/mapbox-sdk/services/directions');

const baseClient = mbxClient({ accessToken: process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN });
const geocodingService = mbxGeocoding(baseClient);
const directionService = mbxDirections(baseClient);

const CheckoutForm = () => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [contactNumber, setNumber] = useState("");
  const [name, setName] = useState("");
  const [postcode, setPostcode] = useState("");
  const [address, setAddress] = useState("");
  const [notes, setNotes] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);
  const [paymentError, setPaymentError] = useState({ hidden: true, message: "" });
  const [showAddressError, setShowAddressError] = useState(false);
  const [addressErrorMsg, setAddressErrorMsg] = useState("");
  const [usingCard, setUsingCard] = useState(false);

  const getDistance = async () => {
    try {
      const geocodeRes = await geocodingService.forwardGeocode({
        query: postcode,
        limit: 1
      }).send();
      const coords = geocodeRes.body.features[0].center;
      const directionRes = await directionService.getDirections({
        profile: 'driving',
        alternatives: true,
        waypoints: [
          {
            coordinates: JSON.parse(process.env.REACT_APP_SHOP_COORDINATES),
          },
          {
            coordinates: coords
          }
        ]
      }).send();
      const shortestRoute = directionRes.body.routes.sort((a, b) => { return a.distance - b.distance })[0];
      return shortestRoute.distance;
    } catch (e) {
      console.log(e);
    }
    return -1;
  }

  const payByCard = async () => {
    const paymentIntent = {
      isDelivery: BasketStore.isDelivery,
      subTotal: BasketStore.subTotal,
      items: BasketStore.addedItems,
      orderNote: BasketStore.orderNotes,
      deliveryNote: notes,
      email,
      postcode,
      address,
      name,
      contactNumber,
      usingCard
    }
    const response = await fetch('/api/getCheckoutSecret', {
      method: "post",
      body: JSON.stringify(paymentIntent),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const { client_secret: clientSecret, error: error } = await response.json();
    if (error) {
      setPaymentError({ hidden: false, message: error });
      setloading(false);
      return;
    }
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name,
          ...email !== "" && { email: email },
          phone: contactNumber,
          address: {
            postal_code: postcode,
            line1: address
          },
        },
      }
    });

    setloading(false);
    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      setPaymentError({ hidden: false, message: result.error.message });
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        setPaymentError({ hidden: true, message: "" });
        const queryStr = "?orderId=" + encodeURIComponent(result.paymentIntent.id);
        store.remove('basket');
        history.push(`/checkout/checkoutSuccess/${queryStr}`);
        // window.location.replace(`${window.location.href}/checkoutSuccess/${queryStr}`);
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      } else {
        store.remove('basket');
        setPaymentError({ hidden: true, message: "" });
        history.push(`/checkout/checkoutFailure`);
        // window.location.replace(`${window.location.href}/checkoutFailure`);
      }
    }
  }

  const payByCash = async () => {
    const paymentIntent = {
      isDelivery: BasketStore.isDelivery,
      subTotal: BasketStore.subTotal,
      items: BasketStore.addedItems,
      orderNote: BasketStore.orderNotes,
      deliveryNote: notes,
      email,
      postcode,
      address,
      name,
      contactNumber,
      usingCard
    }
    const response = await fetch('/api/payByCash', {
      method: "post",
      body: JSON.stringify(paymentIntent),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const { success: success, orderId: orderId, error: error } = await response.json();
    setloading(false);

    if (error) {
      setPaymentError({ hidden: false, message: error });
      return;
    }

    if (success) {
      setPaymentError({ hidden: true, message: "" });
      const queryStr = "?orderId=" + encodeURIComponent(orderId);
      store.remove('basket');
      history.push(`/checkout/checkoutSuccess/${queryStr}`);
    }
  }
  
  const confirm = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setloading(true);
    if (BasketStore.isDelivery) {
      const distance = await getDistance();
      const allowedDistance = parseInt(process.env.REACT_APP_DELIVERY_DISTANCE_IN_METERS);
      if (distance === -1) {
        setAddressErrorMsg("Invalid postcode/address provided, please try again or contact us at 01302323063 instead");
        setShowAddressError(true);
        setloading(false);
        return;
      }
      if (distance > allowedDistance) {
        setAddressErrorMsg("The postcode/address entered is too far away from our shop, please try again or contact us at 01302323063 instead");
        setShowAddressError(true);
        setloading(false);
        return;
      }
    }

    if (usingCard) {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setloading(false);
        return;
      }
      await payByCard();
    } else {
      await payByCash();
    }
  };
  const deliveryNotePlaceholder = 'Delivery notes (optional), for example: deliver around 20:00 tomorrow, call me when you are here';
  const collectionNotePlaceholder = "Collection notes (optional), for example: I will collect my order at 20:00";
  return (

    <Form size='large' onSubmit={(e) => confirm(e)}>
      <Header as='h1' textAlign='center'>
        {BasketStore.isDelivery ? "Delivery" : "Collection"} </Header>
      <Form.Input fluid icon='phone square' iconPosition='left' placeholder='Contact Number' type='number' required name='contactNumber' onChange={(e, { value }) => setNumber(value)} />
      <Form.Input fluid icon='id card' iconPosition='left' placeholder='Name' name='name' required onChange={(e, { value }) => setName(value)} />
      {BasketStore.isDelivery && <Form.Input fluid icon='address card' iconPosition='left' placeholder='Postcode' required name='postcode' onChange={(e, { value }) => setPostcode(value)} />}
      {BasketStore.isDelivery && <Form.Input fluid icon='address card' iconPosition='left' placeholder='Address' required name='address' onChange={(e, { value }) => setAddress(value)} />}
      <Form.Input fluid icon='address card' iconPosition='left' placeholder='Email (optional)' type='email' name='email' onChange={(e, { value }) => setEmail(value)} />
      <TextArea fluid='true' maxLength="500" onChange={(e, { value }) => setNotes(value)} placeholder={BasketStore.isDelivery ? deliveryNotePlaceholder : collectionNotePlaceholder} />
      <BasketPopupView basket={BasketStore} />
      <Modal closeIcon
        onClose={() => setShowAddressError(false)}
        open={showAddressError}>
        <Modal.Content >
          <Header as='h2' textAlign='center' >Error</Header>
          <Divider />
          <Header as='h3' textAlign='center' >{addressErrorMsg}</Header>
          <Button color='blue' className={css(styles.modalAction)} onClick={() => setShowAddressError(false)}>
            OK
                </Button>
        </Modal.Content>
      </Modal>

      <Button fluid color='green' size='large' type='submit' icon disabled={!stripe || loading} loading={loading} id="submitButton" onClick={() => setUsingCard(false)}>
        <span id="button-text"><Icon name='money' /> Pay £{BasketStore.displayTotalNoService} by Cash </span><span id="order-amount"></span>
      </Button>

      <Divider horizontal>Or</Divider>

      <CardSection isDelivery={BasketStore.isDelivery} />
      <Message negative hidden={paymentError.hidden}>
        <p>{paymentError.message}</p>
      </Message>
      <Button fluid primary size='large' type='submit' icon disabled={!stripe || loading} loading={loading} id="submitButton" onClick={() => setUsingCard(true)}>
        <span id="button-text"><Icon name='credit card outline' /> Pay £{BasketStore.displayTotal} by Card</span><span id="order-amount"></span>
      </Button>
    </Form>
  );
}
const styles = StyleSheet.create({
  modalAction: {
    display: 'flex',
    margin: "auto",
    width: "100%",
    justifyContent: "center"
  },
  modalActionContainer: {
    padding: "14px"
  }
});
export default observer(CheckoutForm);
